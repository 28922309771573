<template>
  <div class="login-wrap">
    <div class="content">
      <div class="logo-wrap">
        <img src="../../assets/images/login-logo.png" alt="">
        <div class="tip">
          <p>学历案系统是基于新课标核心素养，在双减教育政策的背景下，根据学生的自主学习习惯而量身打造的互动教学系统。</p>
          <p>本系统集专业学历案课件、分层作业、教学批改、学习日志和学习情况（知识内容）分析为一体，全方位的解决学生自主学习中遇到的各种问题。</p>
        </div>
      </div>
      <div class="centreContent">
        <ul class="tabs">
          <li class="li-tab" @click="cur=0" :class="{active1:cur==0}">
            <img src="../../assets/images/login-student.png"  />
            <div class="t1">学生</div>
          </li>
          <li class="li-tab cursor" @click="clickPar()">
            <img src="../../assets/images/login-parent.png"  />
            <div class="t1">家长</div>
          </li>
          <div class="divTab" v-show="cur==0">
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                status-icon
                :rules="rules"
                label-width="0px"
                class="demo-ruleForm"
            >
              <el-form-item class="form-item" label="" prop="username">

                <el-input
                    @keyup.enter.native="LoginSForm('ruleForm')"
                    v-model="ruleForm.username"
                    placeholder="输入手机号"
                    autocomplete="off"
                    prefix-icon="el-icon-user"
                >
                </el-input>
              </el-form-item>
              <el-form-item class="form-item" label="" prop="pwd">
                <el-input
                    @keyup.enter.native="LoginSForm('ruleForm')"
                    v-model="ruleForm.pwd"
                    placeholder="输入密码"
                    type="password"
                    autocomplete="off"
                    prefix-icon="el-icon-lock"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-item" label="" prop="code">
                <el-input @keyup.enter.native="LoginSForm('ruleForm')" prefix-icon="el-icon-c-scale-to-original" placeholder="验证码" v-model="ruleForm.code"></el-input>
                <div class="code-img">
                  <img :src="codeImgUrl"  alt="看不清，换一张" @click="verificationCode()">
                </div>
              </el-form-item>
              <el-form-item class="form-radio" label="" prop="resource" style="text-align: left">
                <el-radio-group class="loginBtn" @keyup.enter.native="LoginSForm('ruleForm')" v-model="isChecked">
                  <el-radio class="form-radio" label="记住密码"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="loginBtn" @click="LoginSForm('ruleForm')" style="width:100%">登 录</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="small" class="form-shiz" type="primary" @click="toCourseware()">试用</el-button>
                <el-button size="small" class="form-shiz" @click="toStuRegister()">注册</el-button>
              </el-form-item>
              <div class="download">
                <el-button @click="download()" type="text">下载客户端</el-button>
              </div>
            </el-form>
          </div>
          <div class="divTab" v-show="cur==1"></div>
        </ul>
      </div>

    </div>
  </div>
</template>


<script>
import md5 from 'js-md5';
//import HelloWorld from '@/components/HelloWorld.vue'

import { stuLoginObj,CodeObj } from '@/api/login'
export default {
  name: '',
  data() {
    return {
      randomStr:null,
      codeImgUrl:'',
      // 登陆表单
      ruleForm: {
        username: "",
        pwd: "",
        code: "",
        randomStr: '',
        resource: ''
      },
      // 记住密码checked
      checked: false,
      // 记住密码
      isChecked: true, // 记住密码
      arr: [],
      arr2: [],
      // 隐藏错误提示start 这个例子中可以忽略这个值
      isShow: false,
      tips: '',

      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            min: 6,
            max: 30,
            message: '密码长度必须在 6 到 30个字符',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度必须为4个字符',
            trigger: 'blur',
          },
        ],
      },
      cur:0 ,//默认选中第一个tab
    }
  },

  mounted() {
    this.verificationCode()
  },
  methods: {
    // 跳转到试用学历案图书列表
    toCourseware() {
      this.$router.push({
        path: "/mainb/tourCourseware",
        query: {
          onTrial:1,
          EducationCase:1
        }
      });
    },
    download () {
      window.location.href = "https://apk-1257051889.cos.ap-shanghai.myqcloud.com/download/%E5%AD%A6%E5%8E%86%E6%A1%88.exe";
    },
// 设置cookie
    setCookie (name, pwd, exdays) { // 用户名、密码、保存天数
      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)
      // 字符串拼接cookie
      window.document.cookie = 'userName=' + name + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'userPwd=' + pwd + ';path=/;exires=' + exdate.toGMTString()
    },
    // 读取cookie
    getCookie () {
      if (document.cookie.length > 0) {
        // console.log('cookie数据' + document.cookie) // 格式：userName=qq; userPwd=11
        this.arr = document.cookie.split(' ')
        // console.log('111' + this.arr)
        for (let i = 0; i < this.arr.length; i++) {
          this.arr2 = this.arr[i].split('=')
          // console.log('arr2' + this.arr2) // 格式：userName,qq; userPwd,11
          // 判断查找相对应的值
          if (String(this.arr2[0]) === String('userName')) {
            console.log('有valname：' + this.arr2[1]) // 格式 有值val：qq;
            this.ruleForm.username = this.arr2[1].replace(/;/g, '') // 删除尾部的';'
          } else if (String(this.arr2[0]) === String('userPwd')) {
            this.ruleForm.pwd = this.arr2[1].replace(/;/g, '')
            console.log('有valuepwd：' + this.arr2[1])
          }
        }
      }
    },
    // 清楚cookie
    clearCookie () {
      this.setCookie('', '', -1) // 修改2值都为空，天数为负1天就好了
    },
    _isMobile() {
      let flag = navigator.userAgent.match(

              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i

      );
      return flag;
    },
    //登录
    LoginSForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this._isMobile()) {
            this.isMobile=1
            // 移动端
          } else {
            this.isMobile=2
            // PC端
          }
          if (this.isChecked=='记住密码') { // 记住密码
            this.setCookie(this.ruleForm.username, this.ruleForm.pwd, 30)
          } else {
            this.clearCookie() // 清空cookie
          }
          let params = {
            username: this.ruleForm.username,
            pwd: md5(this.ruleForm.pwd),
            code: this.ruleForm.code,
            randomStr: this.ruleForm.randomStr,
            // roleName: 'teacher'
          }
          stuLoginObj(params).then((res) => { // eslint-disable-line no-unused-vars
            if(res.code == 200) {
              localStorage.setItem("userInfo", JSON.stringify(res.data.userinfo))
              // 存储数据
              // this.$store.dispatch("Authorization",res.data.token)
              localStorage.setItem('Authorization', res.data.token)
              this.$router.push({
                path: "/maina/caseHome",
                query: {
                  nowHome:1,
                  stu:1
                }
              });
            } else  {
              let message = res.message
              this.$message({
                message: message,
                type: 'warning',
                duration: 1500
              });
            }
          })



        }
      })

    },
    // 图形验证码
    verificationCode() {
      this.createCode()
      let randomStr = this.randomStr
      console.log(randomStr,'90')
      CodeObj(randomStr).then((res)=> { // eslint-disable-line no-unused-vars
        this.codeImgUrl = res.data.ImgUrl; //
      })
    },
    // 设置4位随机数
    createCode() {
      //var code = '';
      var codeLength = 4;
      //设置随机字符
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
      //循环codeLength 我设置的4就是循环4次
      for (var i = 0; i < codeLength; i++) {
        //设置随机数范围,这设置为0 ~ 36
        var index = Math.floor(Math.random() * 9);
        //字符串拼接 将每次随机的字符 进行拼接
        this.ruleForm.randomStr += random[index];
      }
      let randomStr = this.ruleForm.randomStr
      sessionStorage.setItem('RandomCode', this.ruleForm.randomStr)
      //将拼接好的字符串赋值给展示的code
      this.randomStr = this.ruleForm.randomStr;
    },

    // 跳转到学生注册
    toStuRegister() {
      this.$router.push({
        path: "/register",
        query: {
          noreg:1
        }
      });
    },
    // 跳转到家长登录
    clickPar() {
      this.$router.push({
        path: "/parLogin",
        query: {

        }
      });
    }

  },
  created() {
    localStorage.setItem("Authorization", '')
    this.getCookie()
  },
  components: {
    // 图标


    //注册图标组件名称
  }
}
</script>
<style lang="scss" scoped>

.login-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  background: url("../../assets/images/bj-login.png") center center no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  .content {
    margin: 40px 0px 120px 0px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    /*height: 600px;*/
    border-radius: 20px;
    .logo-wrap {
      width: 500px;
      position:relative;
      margin-top: 20px;
      margin-left: 240px;
      display: block;

      img {
        width: 300px;
        margin-top:50px;
      }
      .tip {
        width: 450px;
        padding: 20px;
        font-weight: bold;
        color: #003F91;
        background: rgba(255, 251, 229, 0.8);
        border: 2px solid #FF4D00;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        opacity: 0.89;
        border-radius: 25px;
        p {
          text-indent: 40px;
        }
      }
    }
    .centreContent {
      width: 520px;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      /*height: 540px;*/
      float: right;
      border-radius: 20px;
      margin-right: -100px;

      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      opacity: 0.9;
      .tabs{
        width: 100%;
        padding:0;
        text-align: center;
        .active1{
          border-radius: 50px;
          border: 1px solid #F77134 ;
          font-weight: bold;
          background: #FFFCB2;
          color: #ff6820;
        }
        .li-tab{
          width: 60px;
          height: 60px;
          border-radius: 50px;
          border: 1px solid #bbb;
          margin: 0px 25px 0 25px;
          display:inline-block;
          text-align: center;
          cursor: pointer;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50px;
          }
          .t1 {
            text-align: center;
          }
        }
        .divTab{
          margin-top: 20px;
          ::v-deep .el-input__inner{
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            padding: 0 54px;
          }
          .form-item {
            position: relative;
            ::v-deep.el-input__icon {font-size: 20px}
            .user {
              color: #666;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }
            .lock {
              color: #666;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }
            .ScaleToOriginal {
              color: #666;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }
            .code-img {
              width: 84px;
              height: 40px;
              position: absolute;
              top: 0px;
              right: 0;
              cursor:pointer;
              img {
                width: 84px;
                height: 40px;
              }
            }
          }

        }
      }
      .download {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -130px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 50px;
    z-index: 99;
    .cop1 {
      height: 30px;
      line-height: 30px;
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
  .dialogHd {
    width: 90%;
  }

}
</style>
